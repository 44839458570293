//breakpoints
$large-desktops: 1280px;
$desktops: 992px;
$tablets: 768px;
$phones: 576px;
$small-phones: 480px;
$old-phones: 320px;

//media
@mixin large-desktops {
  @media screen and (max-width: $large-desktops) {
      @content;
  }
}

@mixin desktops {
  @media screen and (max-width: $desktops) {
      @content;
  }
}

@mixin tablets {
  @media screen and (max-width: $tablets) {
      @content;
  }
}

@mixin phones {
  @media screen and (max-width: $phones) {
      @content;
  }
}

@mixin small-phones {
  @media screen and (max-width: $small-phones) {
      @content;
  }
}

@mixin old-phones {
  @media screen and (max-width: $old-phones) {
      @content;
  }
}

//styles
  h1,h4,p,ul,li {
    margin: 0;
    padding: 0;
  }
  
  ::-webkit-scrollbar { 
    width: 0px;  
    background: transparent;  
  }

  html {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  .parallax-wrapper{
    width: 100%;
  }
  .section {
      &_mission {
        background-color: #efefef;
      }
      &_history {
        background-color: #fafafa;
      }
      &_advisors {
        background-color: #efefef;
      }
  }

  .container {
    &_desc {
      padding: 105px 0;
      @include phones {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    &_market {
      padding: 90px 0;
      @include desktops {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    &_promise {
      padding: 120px 0;
    }
    &_mission {
      display: flex;
      justify-content: center;
      max-width: 1140px;
      padding: 100px 0;
      @include desktops {
        flex-direction: column;
       align-items: center;
      }
    }
    &_history {
      max-width: 920px;
      padding: 150px 0;
      margin-top: -2%;
      text-align: left;
      @include desktops {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    &_team {
      max-width: 1140px;
      padding-top: 90px;
    }
    &_advisors {
      padding: 45px 0 75px 0;
    }
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }

  .title {
      font-family: 'Raleway', sans-serif;
      line-height: 1;
      &_hero {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: white;
        font-size: 86px;
        font-weight: 300;
        @include phones {
          font-size: 46px;
        }
      }
      &_desc {
        color: #767676;
        font-size: 38px;
        font-weight: 400;
        line-height: 1.5;
        margin: 20px 0;
        @include phones {
          font-size: 28px;
        }
      }
      &_market {
        color: #232323;
        margin-bottom: 32px;
      }
      &_promise {
        color: #232323;
        margin-bottom: 32px;
      }
      &_mission {
        color: black;
        font-size: 65px;
        font-weight: 500;
      }
      &_history {
        margin-bottom: 32px;
        color: #232323;
        text-align: center;
      }
      &_team {
        color: black;
        font-size: 55px;
      }
      &_advisors {
        font-family: 'Montserrat', Arial, Helvetica, sans-serif;
        font-size: 54.4px;
        font-weight: 300;
        color: #232323;
      }
      &_letter {
        font-family: 'Montserrat', Arial, Helvetica, sans-serif;
        font-size: 54px;
        @include phones {
          font-size: 44px;
        }
      }
      &__first-letter {
        font-weight: 300;
      }
      &__second-letter {
        font-weight: 700;
      }
  }

  .desc {
    color: #232323;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-size: 28px;
    &_market {
      max-width: 1080px;
      margin: 0 auto;
      line-height: 1.5;
    }
    &_promise {
      color: #767676;
    }
    &_italic {
      margin-top: 5px;
      font-style: italic;
    }
    &_history {
      margin-bottom: 50px;
      font-size: 29px;
      font-weight: 300;
      line-height: 1.5;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .box {
    height: 445px;
    max-width: 520px;
    padding: 32px;
    background-color: #fff;
    text-align: left;
    margin: 10px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    @include phones {
      height: auto;
    }
    &__title {
      margin-bottom: 16px;
      line-height: 1;
    }
    &__desc {
      font-size: 28px;
      color: #767676;
      line-height: 1.7;
    }
    &__letter {
      font-size: 28px;
      color: #232323;
    }
  }
  .team {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
    }
    &__item {
      width: 255px;
      margin: 32px 15px;
      text-align: left;
      font-family: 'Rubik', Arial, Helvetica, sans-serif;
      color: #232323;
      &:hover .team__linkedin {
        opacity: 1;
      }
      @include phones {
        width: 540px;
      }
    }
    &__first-name {
      font-size: 16px;
      font-weight: 700;
    }
    &__last-name {
      font-size: 16px;
      font-weight: 300;
    }
    &__position {
      font-size: 16px;
      font-weight: 300;
      color: #ee5f10;
    }
    &__wrap {
      position: relative;
      margin-bottom: 16px;
    }
    &__photo, &__avatar{
      width: 255px;
      height: 255px;
      @include phones {
        width: 100%;
        height: auto;
        max-width: 540px;
      }
    }
    &__linkedin {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.4);
      transition: all .4s;
      opacity: 0;
    }
  
    &__circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: #fff;
      font-size: 18px;
      font-weight: 900;
      color: #55b4d4;
    }
  }

.jarallax {
  min-height: 50vh;
}

.jarallax-wrap {
  position: relative;
  background-color: #e4e4e6;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  transform: translateX(0);
}

.jarallax-controls {
  padding: 20px;
}
.jarallax-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  &_hero {
    opacity: 0.1;
    background-color: rgb(35, 35, 35);
  }
  &_lags {
    background-color: rgb(177, 163, 116);
  }
  &_planet {
    background-color: rgb(178, 204, 210);
  }
  &_hands {
    opacity: 0.5;
    background-color: rgb(238, 95, 16);
  }

}
