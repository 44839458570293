.react-pdf__Document {
  background-color: #fafafa;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  display: none;
}
