$orange-primary: #f2732f;

.for-lawyers-page {
  width: 100%;

  .button {
    padding: 0rem 3rem;
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    box-shadow: none;
    border-radius: 100px;
    height: 50px;
    min-height: 50px;
    background-color: $orange-primary;
  }

  .bold-text {
    font-weight: 600;
  }

  .invoicing-tutorial-link {
    text-decoration: none;
    color: #767676;
  }

  .heading-layer {
    background: linear-gradient(45deg, #55b4d4, #0f7699);
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_title {
      font-family: 'Raleway', sans-serif;
      font-size: 5.4rem;
      font-weight: 300;
      color: white;
      margin-bottom: 45px;
    }

    &_subtitle {
      font-family: 'Raleway', sans-serif;
      font-size: 2.4rem;
      font-weight: 300;
      color: white;
      margin-bottom: 45px;
    }

    @media (max-width: 768px) {
      &_title {
        font-size: 4.32rem;
        font-size: calc(2.54rem + (5.4 - 2.54) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc(1.4 * (2.54rem + (5.4 - 2.54) * ((100vw - 20rem) / (48 - 20))));
      }
    }
  }

  .sub-heading-layer {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 3.4rem;
      font-weight: 300;
      color: black;
      margin-bottom: 45px;
      text-align: center;
    }

    &_subtitle {
      font-family: 'Montserrat', sans-serif;
      font-size: 3.4rem;
      font-weight: 300;
      color: $orange-primary;
      margin-bottom: 45px;
    }
  }

  .benefit-cards {
    display: flex;
    justify-content: space-around;
    padding: 70px 120px;
    flex-wrap: wrap;
    background-color: white;

    &_wrapper {
      margin: 30px;

      .benefit-card {
        height: 300px;
        width: 330px;
        color: white;

        &__front {
          font-family: 'Montserrat', sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $orange-primary;
          font-size: 25px;
          font-weight: 600;
        }

        &__back {
          background-color: #465052;
          padding: 30px;
          font-family: 'Rubik', sans-serif;
          font-size: 1rem;
          line-height: 1.6;
          font-weight: 300;
        }
      }
    }
  }

  .steps-container {
    background-image: url('../../static/xiraForLawyers/meeting_loby.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 60px;
    flex-direction: column;

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      color: white;
      font-size: 3.4rem;
    }
  }

  .instruction-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 3.4rem;
      font-weight: 300;
    }

    .instruction-subsection {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &_container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 40px 100px;
      }

      &_number {
        color: $orange-primary;
        font-family: 'Raleway', sans-serif;
        font-size: 5.4rem;
        font-weight: 300;
        margin-bottom: 25px;
      }

      &_subtitle {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8rem;
        font-weight: 300;
        margin-bottom: 25px;
      }

      &_list_item {
        color: #767676;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8rem;
      }
    }
  }

  .pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    padding: 30px;

    .pricing-table {
      width: 100%;
      max-width: 1000px;
    }

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 3.4rem;
      font-weight: 300;
      margin-bottom: 15px;
      text-align: center;
    }

    &_subtitle {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.8rem;
      margin-bottom: 30px;
      font-weight: 300;
      text-align: center;
    }

    .pricing-row {
      padding: 30px;
      border-top: 1px solid #efefef;
      background-color: #ffffff;

      &_header {
        display: flex;
        justify-content: space-between;
        @media(max-width: 400px){
          flex-direction: column;
        }
      }

      &_title {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
        color: black;
        margin-bottom: 20px;
      }

      &_price {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8rem;
        font-weight: 300;
        color: #767676;
        min-width: 130px;
        margin-left: 30px;
        text-align: end;
      }

      &_description {
        font-family: 'Rubik', sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: #767676;
      }
    }
  }

  .footer {
    background-color: $orange-primary;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 3.4rem;
      color: white;
      margin-bottom: 20px;
      font-weight: 300;
    }

    &_button {
      color: white;
      border: 2px solid white;

      &:hover {
        color: black;
        background-color: white;
      }
    }
  }
}
