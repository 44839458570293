* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /*min-width: 1280px;*/
  overflow-x: auto;
  position: relative;
}

.main-content {
  flex: 1;
  min-height: calc(100vh - 50px);
  overflow-y: auto;
}

.account {
  height: 100%;
  margin-left: 240px;
}

.info-wrapper {
  display: flex;
  height: 100%;
  position: relative;
}

.responsiveRatingButton {
  margin: 0 9px;
}
@media (max-width: 940px) {
  .responsiveRatingButton {
    margin: 0 5px;
  }
}
.raitingRoot {
  width: 100%;
}
