@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.fc {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  .fc-button-primary:focus {
    box-shadow: none;
  }

  .fc-nonbusiness {
    background-color: #e5e4e4;
  }

  .fc-now-indicator-arrow {
    display: none;
  }

  .fc-now-indicator-line {
    border-color: black;

    &:before {
      content: ' ';
      position: absolute;
      top: -3px;
      left: -6px;
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 8px solid black;
    }
  }

  .fc-toolbar {
    .fc-button {
      padding: 4px 12px;
      background-color: #fafafa;
      border: 1px solid #d0cfcf;
      color: #666564;
      font-weight: 500;
    }

    .fc-center {
      color: #51504f;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.4;
    }
  }

  .fc-head-container {
    border-top: 0;
    border-left: 0;
    border-bottom: 1px solid #51504f;

    .fc-day-header {
      .fc-calendar-header-day {
        text-transform: uppercase;
        font-size: 9px;
        color: #51504f;
        letter-spacing: 0.4px;
        font-weight: normal;
      }

      .fc-calendar-header-number-container {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .fc-calendar-header-number {
          font-size: 16px;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px;
          font-weight: normal;
          color: #51504f;
        }
      }
    }

    .fc-day-header.fc-today {
      .fc-calendar-header-day {
        color: #ee5f10;
      }

      .fc-calendar-header-number {
        background-color: #feebd2;
        color: #ee5f10;
      }
    }
  }

  .fc-widget-content {
    border-left: 0;

    .fc-bg {
      .fc-day.fc-today {
        background-color: rgba(238, 95, 16, 0.05);
      }
    }

    .fc-slats {
      .fc-axis.fc-time {
        text-transform: uppercase;
        color: #51504f;
      }

      .fc-minor {
        .fc-axis.fc-time {
          border-top: none;
        }
      }
    }

    .fc-event {
      cursor: pointer;
      background-color: white;
      border: none;

      .fc-content {
        height: 100%;
        display: flex;
      }
    }
  }

  .fc-list-view {
    border: none;

    .fc-list-table {
      border: none;

      .fc-list-item {
        border: none;

        .fc-list-item-time {
          padding-left: 125px;
        }

        .fc-widget-content {
          border: none;
        }
      }

      .fc-widget-header {
        background-color: white;
        border: none;

        .fc-list-heading-main {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.4px;
          height: 8px;
        }
      }

      .fc-list-current-date-indicator {
        .fc-list-heading-main {
          color: #ee5f10;
          position: relative;

          &:before {
            content: ' ';
            height: 24px;
            width: 24px;
            background-color: #f2732f;
            position: absolute;
            border-radius: 50%;
            opacity: 0.2;
            top: -4px;
            left: -4px;
          }
        }
      }

      .fc-list-current-date-indicator-one-digit-in-date {
        .fc-list-heading-main {
          &:before {
            left: -8px;
            top: -3px;
            height: 22px;
            width: 22px;
          }
        }
      }

      tbody {
        .fc-list-heading:not(:first-child) {
          .fc-widget-header {
            border-top: 1px solid #e5e4e4;
          }
        }

        .fc-list-heading + .fc-list-item {
          .fc-list-event-row {
            margin-top: -23px;
          }
        }
      }
    }

    .fc-list-table tbody > .fc-list-event-future-event {
      .fc-list-event-row {
        border-top: 1px solid black;
        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          top: -3px;
          left: 0;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 8px solid black;
        }
      }
    }

    .fc-list-table tbody > .fc-list-event-future-event ~ .fc-list-event-future-event {
      .fc-list-event-row {
        border-top: none;
        &:before {
          content: none;
          border: 0;
        }
      }
    }

    .fc-list-empty {
      background-color: white;
      font-size: 20px;
      text-align: center;
    }
  }
}

.fc-time-grid {
  overflow: hidden;
}
