$grey-medium: #969696;
$grey-dark: #575757;


.react-tel-input{
    &:hover .flag-dropdown{
        border-left-color: $grey-dark !important;
        border-top-color: $grey-dark !important;
        border-bottom-color: $grey-dark !important;
    }
    &:hover input{
        border-color: $grey-dark !important;
    }
    & input{
        &:focus{
            border-color: $grey-dark !important;
        }
        &:focus + .flag-dropdown { 
            border-left-color: $grey-dark !important;
            border-top-color: $grey-dark !important;
            border-bottom-color: $grey-dark !important;
        }
    }
}
.flag-dropdown, .react-tel-input > input{
    transition: 0.1s linear;
}